<template>
  <div
    :class="[
      'login-page tw-absolute tw-z-10 tw-h-full tw-w-full tw-top-0 tw-left-0 tw-bg-dark tw-pt-15 tw-flex tw-flex-col tw-items-center tw-overflow-hidden',
      isZD ? '' : 'tw-pt-6',
    ]"
  >
    <!-- <FullLogo></FullLogo> -->
    <div
      class="tw-rounded-sm tw-bg-white tw-pt-9 tw-pb-16 tw-px-24 tw-mt-14 tw-flex tw-flex-col tw-items-center tw-relative tw-z-30"
    >
      <h1 class="tw-text-4xl">👋</h1>
      <h2 class="tw-font-semibold tw-text-3xl">Welcome Back</h2>
      <a-form
        :model="formData"
        name="basic"
        autocomplete="off"
        layout="vertical"
        class="tw-flex tw-flex-col tw-flex-wrap tw-mt-6"
      >
        <a-form-item
          class="tw-w-72 tw-px-2"
          name="email"
          :rules="[{ required: true, message: 'Please input your email!' }]"
        >
          <template #label>
            <span>Email</span>
          </template>
          <a-input v-model:value="formData.email" />
        </a-form-item>

        <a-form-item
          class="tw-w-72 tw-px-2"
          name="password"
          :rules="[{ required: true, message: 'Please input your password!' }]"
        >
          <template #label>
            <span>Password</span>
          </template>
          <a-input-password v-model:value="formData.password" />
        </a-form-item>

        <a-form-item>
          <a-button
            type="secondary"
            html-type="submit"
            :loading="isLoading"
            :class="[
              {
                'tw-mx-auto tw-w-72 tw-flex tw-justify-center tw-items-center tw-bg-yellow-500 tw-text-white': true,
                'tw-opacity-75 tw-cursor-not-allowed':
                  Object.values(formData).includes(''),
              },
            ]"
            size="large"
            @click="login()"
          >
            Login
          </a-button>
        </a-form-item>
        <p v-if="err" class="tw-text-red-500 tw-text-center">{{ err }}</p>
      </a-form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { notification } from 'ant-design-vue'

definePageMeta({ auth: false })
const err = ref(false)
let signIn: any = null
if (!process.server) {
  const sess = useSession()
  signIn = sess.signIn
}
const isZD = ref(false)
const formData = reactive({
  email: '',
  password: '',
  app: 'admin',
})

const route = useRoute()
const callback = route.query.callbackUrl

const login = async () => {
  const { error, url } = await signIn('credentials', {
    email: formData.email,
    password: formData.password,
    app: formData.app,
    grant_type: 'password',
    callbackUrl: callback ? callback : '/',
    redirect: false,
  })
  if (error) {
    err.value = error
  } else {
    return navigateTo(url, { external: true })
  }
}
const isLoading = false

onMounted(() => {
  if (window.self !== window.top) isZD.value = true
  if (isZD.value === true) formData.app = 'zendesk'
  else formData.app = 'admin'
})
</script>

<style lang="scss">
.login-page {
  .ant-form-item-label,
  .ant-form-item-control {
    max-height: 30px;
  }
}
</style>
